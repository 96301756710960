import Layout from '../../components/Layout/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { withAuthorization } from '../../session'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createJob, getJob, updateJob } from '../../db/job'
import { getUser } from '../../db/user'
import AuthUserContext from '../../session/context'
import FirebaseContext from '../../firebase/context'
import { USA_STATES } from '../../constants/defines'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import { CITIES_BY_STATE } from '../../constants/citiesByState'
import industries from '../../constants/Industries'

const plus = { days: 30 }
const MINIMAL_DATE = DateTime.utc()
  .minus({ hours: 8 })
  .plus(plus)
  .startOf('day')
  .toJSDate()

const INITIAL_STATE = {
  title: '',
  industry: undefined,
  description: '',
  company: '',
  personal: '',
  state: undefined,
  city: undefined,
  zip: '',
  contract: '',
  salary: '',
  directions: '',
  application: '',
  level: '',
  expiration: MINIMAL_DATE,
  userId: '',
}

function Job(props) {
  let { id } = useParams()
  const [postButtonLabel, setPostButtonLabel] = useState(id ? 'Update' : 'Post')
  const [formValidators, setFormValidators] = useState({})
  const navigate = useNavigate()
  const user = props.user
  const { setUser } = useContext(AuthUserContext)
  const firebaseContext = useContext(FirebaseContext)
  const db = props.firebase.getdb()
  const [job, setJob] = useState({ ...INITIAL_STATE })
  const [btnTitle, setBtnTitle] = useState('Unpublish')

  useEffect(() => {
    if (id) {
      getJob(db, id).then((item) => {
        if (
          item.status === undefined ||
          item.status === null ||
          item.status === 'pending'
        )
          setBtnTitle('Publish')
        else setBtnTitle('Unpublish')

        if (!item?.expiration?.toDate) {
          item.expiration = new Date()
        }

        if (item?.expiration?.toDate) {
          item.expiration = item.expiration.toDate()
        }

        setJob(item)
      })
    } else {
      setJob({ ...INITIAL_STATE })
    }
  }, [id, db])

  const onChangeNumber = (e) => {
    const { name, value } = e.target
    if (/^\d*$/.test(value)) {
      setJob({ ...job, [name]: value })
    }
    // value = type === 'checkbox' ? checked : value
  }
  const handleDisableJob = async (e) => {
    e.preventDefault()
    if (job.contract === '') job.contract = 'Remote'
    if (id) {
      if (job.status === undefined || job.status === null) {
        job.status = 'published'
        updateJob(db, id, job)
        toast.success('Job published')
        setBtnTitle('UnPublish')
      } else if (job.status === 'pending') {
        job.status = 'published'
        updateJob(db, id, job)
        toast.success('Job published')
        setBtnTitle('UnPublish')
      } else if (job.status === 'published') {
        job.status = 'pending'
        updateJob(db, id, job)
        toast.success('Job Unpublished')
        setBtnTitle('Publish')
      }
    }
  }

  const handleInvalid = useCallback(
    (e) => {
      if (e.target.name === 'zip') {
        e.currentTarget.setCustomValidity('The Zip Code must be 5 digits long.')
      }
      setFormValidators({ ...formValidators, [e.target.name]: true })
    },
    [formValidators],
  )

  const handleInput = useCallback(
    (e) => {
      e.currentTarget.setCustomValidity('')
      setFormValidators({ ...formValidators, [e.target.name]: false })
      if (e.target.type === 'select-one') {
        setJob({ ...job, [e.target.name]: e.target.value })
      }
    },
    [formValidators, job],
  )

  const onSubmit = async (e) => {
    e.preventDefault()
    setPostButtonLabel('Posting...')
    if (job.contract === '') job.contract = 'Remote'
    if (id) {
      job.status = 'published'
      updateJob(db, id, job)
      setPostButtonLabel(id ? 'Update' : 'Post')
      toast.success('Job has been updated')
    } else {
      const lastUser = await getUser(db, user.uid)
      if (!lastUser.plan || lastUser.plan === '') {
        setPostButtonLabel(id ? 'Update' : 'Post')
        toast.error('No membership. Please purchase a plan')
        return
      }

      if (lastUser.credit === 0) {
        setPostButtonLabel(id ? 'Update' : 'Post')
        toast.error('No credits. Please purchase a plan to increase credits')
        return
      }

      const jobId = await createJob(db, user, job)
      if (jobId) {
        const updatedUser = { ...user, credit: user.credit - 1 }
        setUser(updatedUser)
        console.log('Job Created. ', jobId)
        navigate('/profile?tab=2')
        setTimeout(() => {
          toast.success('Job has been posted')
        }, 500)
      }
    }
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value
    setJob({ ...job, [name]: value })
    if (name === 'state') {
      setJob({ ...job, [name]: value, city: '' })
    }
  }

  const clickSignOut = (event) => {
    event.preventDefault()
    firebaseContext.doSignOut()
  }

  if (user.credit <= 0 && !id) {
    navigate('/profile?tab=3')
    return <></>
  }

  return (
    <>
      <Layout>
        <div>
          <section className="section-box mt-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <Link to="/profile?tab=2">
                    <div className="font-sm text-brand-2 d-flex">
                      <img
                        src="/assets/imgs/page/homepage2/arrow-left.svg"
                        alt="arrowLeft"
                      />
                      Job Posts
                    </div>
                  </Link>
                  <div className="mb-30 text-start">
                    <h3 className="mt-30 color-brand-1">{user.name}</h3>
                    <div className="font-md color-text-paragraph-1">
                      {user.company}
                    </div>
                    <a href="/pricing">
                      <div className="font-md color-text-paragraph-1 mt-10">
                        {user.credit} Credits
                      </div>
                    </a>
                  </div>
                  <div className="box-nav-tabs nav-tavs-profile mb-5">
                    <ul className="nav" role="tablist">
                      <li>
                        <Link
                          className="btn btn-border aboutus-icon mb-20 font-sm"
                          to="/profile?tab=1"
                        >
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="btn btn-border recruitment-icon mb-20 active font-sm"
                          to="/profile?tab=2"
                        >
                          Job Postings
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="btn btn-border coin-icon mb-20 font-sm"
                          to="/profile?tab=3"
                        >
                          Billing and Packages
                        </Link>
                      </li>
                      <div className="border-bottom pt-10 pb-10" />
                      <div className="mt-20 mb-20 text-start">
                        <a
                          className="link-red"
                          onClick={clickSignOut}
                          style={{ cursor: 'pointer' }}
                        >
                          Sign&nbsp;Out
                        </a>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-12 mb-50 ">
                  <div className="content-single">
                    <div className="tab-content">
                      <div className="tab-pane fade show active">
                        <form className="row form-contact" onSubmit={onSubmit}>
                          <div className="col-lg-9 col-md-12">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Job Title
                              </label>
                              <input
                                className={`form-control ${formValidators['title'] ? 'invalid' : ''}`}
                                onInvalid={handleInvalid}
                                onInput={handleInput}
                                type="text"
                                required
                                name="title"
                                value={job.title}
                                onChange={onChange}
                              />
                            </div>
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Company
                              </label>
                              <input
                                className={`form-control ${formValidators['company'] ? 'invalid' : ''}`}
                                type="text"
                                required
                                name="company"
                                onInvalid={handleInvalid}
                                onInput={handleInput}
                                value={job.company}
                                onChange={onChange}
                              />
                            </div>
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Add your job description
                              </label>
                              <textarea
                                className={`form-control ${formValidators['description'] ? 'invalid' : ''}`}
                                rows={4}
                                required
                                onInvalid={handleInvalid}
                                onInput={handleInput}
                                name="description"
                                value={job.description}
                                onChange={onChange}
                              />
                            </div>
                            <div className="row">
                              <div className="form-group ">
                                <label className="font-sm color-text-mutted mb-10">
                                  State
                                </label>
                                <select
                                  className={`form-input mr-10 select-active ${formValidators['state'] ? 'invalid' : ''}`}
                                  required
                                  onInvalid={handleInvalid}
                                  onInput={handleInput}
                                  name="state"
                                  value={job?.state}
                                  onChange={onChange}
                                  defaultValue=""
                                >
                                  <option value={''} disabled>
                                    Select a State
                                  </option>
                                  {USA_STATES.map((state, index) => {
                                    return (
                                      <option
                                        value={state}
                                        key={`state_${index}_${state}`}
                                      >
                                        {state}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group ">
                                  <label className="font-sm color-text-mutted mb-10">
                                    City
                                  </label>
                                  <select
                                    className={`form-input mr-10 select-active ${formValidators['city'] ? 'invalid' : ''}`}
                                    required
                                    onInvalid={handleInvalid}
                                    onInput={handleInput}
                                    name="city"
                                    value={job?.city}
                                    onChange={onChange}
                                    defaultValue=""
                                  >
                                    <option value="" disabled>
                                      Select a City
                                    </option>
                                    {!!job?.state &&
                                      CITIES_BY_STATE[job.state].map(
                                        (city, index) => (
                                          <option
                                            value={city}
                                            key={`${job?.state}_${index}_${city}`}
                                          >
                                            {city}
                                          </option>
                                        ),
                                      )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Zip code (Optional)
                                  </label>
                                  <input
                                    className={`form-control ${formValidators['zip'] ? 'invalid' : ''}`}
                                    pattern="\d*"
                                    inputMode="numeric"
                                    name="zip"
                                    onInvalid={handleInvalid}
                                    onInput={handleInput}
                                    // required
                                    value={job.zip}
                                    minLength={5}
                                    onChange={onChangeNumber}
                                    maxLength={5}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Salary (optional)
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="salary"
                                pattern="\d*"
                                inputMode="numeric"
                                value={job.salary}
                                onChange={onChangeNumber}
                              />
                            </div>

                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Application Directions
                              </label>
                              <textarea
                                className={`form-control ${formValidators['directions'] ? 'invalid' : ''}`}
                                rows={4}
                                required
                                onInvalid={handleInvalid}
                                onInput={handleInput}
                                name="directions"
                                value={job?.directions}
                                onChange={onChange}
                              />
                            </div>

                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Application URL (optional)
                              </label>
                              <input
                                className="form-control"
                                type="url"
                                name="application"
                                value={job.application}
                                onChange={onChange}
                              />
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group ">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Industry
                                  </label>
                                  <select
                                    className={`form-input mr-10 select-active ${formValidators['industry'] ? 'invalid' : ''}`}
                                    required
                                    onInvalid={handleInvalid}
                                    onInput={handleInput}
                                    name="industry"
                                    value={job?.industry}
                                    onChange={onChange}
                                    defaultValue={''}
                                  >
                                    <option value="" disabled>
                                      Select your industry
                                    </option>
                                    {industries.map((item, index) => {
                                      return (
                                        <option
                                          value={item.key}
                                          key={`industry_${index}_${item.key}`}
                                        >
                                          {item.value}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group flex-column d-flex">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Expiration
                                  </label>
                                  <DatePicker
                                    selected={job?.expiration}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                      setJob({ ...job, expiration: date })
                                    }}
                                    required
                                    onInvalid={handleInvalid}
                                    onInput={handleInput}
                                    show
                                    showFullMonthYearPicker
                                    onSelect={(date) => {
                                      setJob({ ...job, expiration: date })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="box-button mt-15 d-flex">
                              <button
                                disabled={postButtonLabel === 'Posting...'}
                                className="btn btn-default font-sm"
                                type="submit"
                                style={{ width: 120 }}
                              >
                                {postButtonLabel}
                              </button>

                              {id && (
                                <button
                                  className="btn btn-grey btn-shadow ml-20 hover-up"
                                  onClick={handleDisableJob}
                                  style={{ width: 107 }}
                                >
                                  {btnTitle}
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(Job)
