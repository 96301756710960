import React, { useCallback, useState } from 'react'
import { USA_STATES } from '../../constants/defines'
import { useSearchParams } from 'react-router-dom'
import { withFirebase } from '../../firebase'
import SearchIcon from '../../assets/icons/SearchIcon'

const SearchBanner = ({ onSubmit }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(searchParams.get('search') ?? '')
  const [state, setState] = useState(searchParams.get('state') ?? '')

  const onChangeSearch = useCallback(
    (searchParam) => {
      const updatedParams = new URLSearchParams(searchParams)

      if (searchParam !== '') {
        updatedParams.set('search', searchParam ?? '')
      } else {
        updatedParams.delete('search')
      }
      setSearch(searchParam)
      setSearchParams(updatedParams)
    },
    [searchParams, setSearchParams],
  )

  const onChangeState = useCallback(
    (newState) => {
      const updatedParams = new URLSearchParams(searchParams)
      if (newState !== '') {
        updatedParams.set('state', newState ?? '')
      } else {
        updatedParams.delete('state')
      }
      setSearchParams(updatedParams)
      setState(newState)
    },
    [searchParams, setSearchParams],
  )

  return (
    <div>
      <section className="section-box-2">
        <div className="banner-hero banner-single banner-single-bg">
          <div className="block-banner text-center">
            <h3 className="wow animate__animated animate__fadeInUp">
              <span className="text-52 color-white">
                Jobs from America’s <br /> top companies
              </span>
            </h3>
            <div
              className="form-find mt-40 wow animate__animated animate__fadeIn"
              data-wow-delay=".2s"
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  onSubmit(search, state)
                }}
              >
                <div className="input-wrapper border-top-2">
                  <input
                    className="form-input input-keysearch mr-10 "
                    type="text"
                    name="search"
                    onChange={(e) => onChangeSearch(e.target.value)}
                    value={search}
                    placeholder="Search by title"
                  />
                </div>

                <div className="flex justify-content-end">
                  <div className="box-industry ">
                    <select
                      className="form-input mr-10 input-location"
                      style={{ marginLeft: '-.7rem' }}
                      value={state}
                      onChange={(e) => onChangeState(e.target.value)}
                    >
                      <option value="">All States</option>
                      {USA_STATES.map((it) => {
                        return (
                          <option
                            key={`homepage-state-select-${it}`}
                            value={it}
                          >
                            {it}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <button
                    className="btn btn-default btn-find font-sm"
                    type={'submit'}
                  >
                    <SearchIcon />
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default withFirebase(SearchBanner)
