export default function Pagination({
  currentPage,
  getPaginationGroup,
  onPressPage,
}) {
  return (
    <>
      <ul className="pager">
        <li onClick={(e) => onPressPage(currentPage + 1)}>
          <a className="pager-prev" />
        </li>
        {getPaginationGroup.map((item, index) => {
          return (
            <li onClick={() => onPressPage(+item)} key={index}>
              <a
                className={
                  currentPage === item ? 'pager-number active' : 'pager-number'
                }
              >
                {item}
              </a>
            </li>
          )
        })}

        <li onClick={(e) => onPressPage(Math.max(currentPage - 1, 0))}>
          <a className="pager-next" />
        </li>
      </ul>
    </>
  )
}
