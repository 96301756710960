import { Collections, Documents } from '../constants/defines'
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getIndustryKeys } from '../constants/Industries'

const INDUSTRY_KEYS = getIndustryKeys()

const deleteJob = (db, docId) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, Collections.JOBS, docId))
      .then(() => {
        console.log('Job successfully deleted!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting Job: ', error)
        reject()
      })
  })
}

const createJob = async (db, user, data) => {
  try {
    const newDocRef = doc(collection(db, Collections.JOBS))
    const jobId = newDocRef.id

    const payload = {
      ...data,
      userId: user.uid,
      userCompany: user.company,
      status: 'published',
      isEmployer: !user?.isAdmin,
      isAdmin: user?.isAdmin,
      id: jobId,
    }
    payload.createdAt = new Date()
    const { uid } = user
    const userDocRef = doc(db, Collections.USERS, uid)

    const docSnap = await getDoc(userDocRef)
    if (!docSnap.exists()) {
      console.log('Document does not exist')
      return
    }

    const userData = docSnap.data()

    if (userData.credit === 0) {
      console.log('User has no credits, not allowed to create a new job post')
      return
    }

    await setDoc(newDocRef, payload)
    console.log('New Job created with ID: ', jobId)

    userData.credit = userData.credit - 1
    await updateDoc(userDocRef, {
      ...userData,
    })

    console.log('User Data successfully updated!')

    return jobId
  } catch (e) {
    console.error('CreateJob error:', e)
    return null
  }
}

const getJob = (db, uid) => {
  return new Promise((resolve) => {
    const docRef = doc(db, Collections.JOBS, uid)
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data() }
        resolve(data)
      } else {
        resolve(null)
      }
    })
  })
}

const updateJob = (db, docId, item) => {
  console.log('updating job')
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.JOBS, docId)
    updateDoc(docRef, {
      ...item,
    })
      .then(() => {
        console.log('Job Data successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating Job Data: ', error)
        reject()
      })
  })
}

const getJobs = (db, sortBy = 'desc') => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, Collections.JOBS),
      where('status', '==', 'published'),
      orderBy('createdAt', sortBy),
    )

    return getDocs(q)
      .then((querySnapshot) => {
        let items = []

        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id })
        })
        resolve(items)
      })
      .catch((error) => {
        console.log('getJobs: ', error)
        reject()
      })
  })
}

const getJobsV2 = async (db, filters, limitPerPage, lastVisible) => {
  try {
    const finalFilters = [...filters];
    if (lastVisible) {
      finalFilters.push(startAfter(lastVisible));
    }

    finalFilters.push(limit(limitPerPage));

    const jobQuery = query(collection(db, Collections.JOBS), ...finalFilters);
    const querySnapshot = await getDocs(jobQuery);

    const items = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] ?? null;

    let counter = null;
    if (!lastVisible) {
      const counterQuerySnapshot = await getCountFromServer(
        query(collection(db, Collections.JOBS), ...filters)
      );
      counter = counterQuerySnapshot.data().count;
    }

    return { items, newLastVisible, counter };
  } catch (error) {
    console.error('getJobsV2 Error:', error);
    return { items: [], newLastVisible: null, counter: 0 };
  }
};



const getJobsV2Counter = async (db, filters) => {
  try {
    const jobQuery = query(collection(db, Collections.JOBS), ...filters)

    const counterQuerySnapshot = await getCountFromServer(
      query(collection(db, Collections.JOBS), ...filters),
    )

    return counterQuerySnapshot.data().count
  } catch (error) {
    console.error('getJobs Failed:', error)
    return null
  }
}

const getCounter = () => {}

const getMyJobs = (db, sortBy = 'desc') => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, Collections.JOBS),
      orderBy('createdAt', sortBy),
    )

    return getDocs(q)
      .then((querySnapshot) => {
        let items = []

        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id })
        })
        resolve(items)
      })
      .catch((error) => {
        console.log('getJobs: ', error)
        reject()
      })
  })
}

const getJobIndustryCounters = async (db, queryFilters) => {
  const queries = INDUSTRY_KEYS.map(async (industry) => {
    const jobQuery = query(
      collection(db, Collections.JOBS),
      where('industry', '==', industry),
      where('status', '==', 'published'),
    )
    const counterQuerySnapshot = await getCountFromServer(jobQuery)
    return { [industry]: counterQuerySnapshot.data().count }
  }, 20)

  const results = await Promise.all(queries)
  return results.reduce(
    (counters, current) => ({ ...counters, ...current }),
    {},
  )
}

const updateIndustryCounters = async (db, payload) => {
  const newDocRef = doc(db, Documents.INDUSTRY_COUNTERS)
  await updateDoc(newDocRef, {
    ...payload,
    updatedAt: new Date(),
  })
}

const getIndustryCounters = async (db, payload) => {
  const newDocRef = doc(db, Documents.INDUSTRY_COUNTERS)
  const docSnap = await getDoc(newDocRef)
  if (!docSnap.exists()) {
    console.log('Document does not exist')
    return
  }
  return docSnap.data()
}

const getJobsV3 = async (db, filters, lastVisible) => {
  try {
    let allItems = [];
    let newLastVisible = lastVisible;
    let hasMore = true;

    while (hasMore) {
      const currentFilters = [...filters];
      if (newLastVisible) {
        currentFilters.push(startAfter(newLastVisible));
      }

      const jobQuery = query(collection(db, Collections.JOBS), ...currentFilters);
      const querySnapshot = await getDocs(jobQuery);

      if (querySnapshot.empty) {
        hasMore = false;
        break;
      }

      querySnapshot.forEach((doc) => {
        allItems.push({ ...doc.data(), id: doc.id });
      });

      newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] ?? null;
      hasMore = newLastVisible !== null;
    }

    let counter = null;
    if (!lastVisible) {
      const counterQuerySnapshot = await getCountFromServer(
        query(collection(db, Collections.JOBS), ...filters)
      );
      counter = counterQuerySnapshot.data().count;
    }

    return { items: allItems, newLastVisible, counter };
  } catch (error) {
    console.error('getJobs Failed:', error);
    return { items: null, newLastVisible: null, counter: null };
  }
};


export {
  createJob,
  getJob,
  getJobs,
  deleteJob,
  updateJob,
  getMyJobs,
  getJobsV2,
  getJobIndustryCounters,
  updateIndustryCounters,
  getIndustryCounters,
  getJobsV2Counter,
  getJobsV3
}
