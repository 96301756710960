import { useCallback, useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import JobCard from '../../components/elements/JobCard'
import { withFirebase } from '../../firebase'
import FiltersBar from './FiltersBar'
import { useSearchParams } from 'react-router-dom'
import SearchBanner from './SearchBanner'
import Pagination from '../../components/filter/Pagination'
import formatNumber from '../../utils/formatNumber'
import searchWithAlgolia from '../../hooks/searchWithAlgolia'
import { useDebounce } from '@uidotdev/usehooks'

const JobsList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [query] = useState()
  const [results, setResults] = useState({})
  const [error] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [config, setConfig] = useState()
  const debounceConfig = useDebounce(config, 300)

  useEffect(() => {
    if (debounceConfig) {
      console.log(debounceConfig)
      searchWithAlgolia(debounceConfig).then((data) => {
        setIsLoading(false)
        setResults({ ...data })
      })
    }
  }, [debounceConfig])

  useEffect(() => {
    setIsLoading(true)
    let filters = ''
    let today = Date.now()
    let page = 1
    const query = searchParams.get('search') ?? ''

    const state = searchParams.get('state')
    if (state) {
      filters += `state:"${state}" AND `
    }

    const city = searchParams.get('city')
    if (city) {
      filters += `city:"${city}" AND `
    }

    const localPage = searchParams.get('page')
    if (page) {
      page = Math.max(+localPage - 1, 0)
    }

    const industries = searchParams.get('industries')
    if (industries) {
      filters += '('
      const industriesArray = industries.split(',')
      industriesArray.forEach((industry, index) => {
        filters += `industry:"${industry}"`
        if (index === industriesArray.length - 1) {
          filters += `) AND `
        } else {
          filters += ` OR `
        }
      })
    }

    const jobPosted = searchParams.get('job-posted')
    let postedDay
    if (jobPosted) {
      postedDay = Date.now() - jobPosted * 24 * 60 * 60 * 1000
      filters += `createdAt > ${postedDay} AND `
    }

    filters += `status:published AND expiration > ${today}`
    console.log({
      index: 'prod_jobs',
      hitsPerPage: 50,
      query,
      filters: filters,
      page,
    })
    setConfig({
      index: 'prod_jobs',
      hitsPerPage: 50,
      query,
      filters: filters,
      page,
    })
  }, [searchParams])

  const onPressPage = useCallback(
    (newPage) => {
      const updatedParams = new URLSearchParams(searchParams)
      updatedParams.set('page', newPage)
      setSearchParams(updatedParams)
    },
    [searchParams, setSearchParams],
  )

  const onSubmit = (search) => {
    // setSearch(search)
  }

  const renderJobs = () => {
    if (error) {
      return (
        <div className="row">
          <span>There was an error fetching jobs, please refresh </span>
        </div>
      )
    }
    if (isLoading || !results || !results?.hits) {
      return (
        <div className="row">
          <span>Loading results... </span>
        </div>
      )
    }

    if (!isLoading && results && results?.hits.length === 0) {
      return <span>There is no jobs matching the filters.</span>
    }

    return (
      <div className="row">
        {results?.hits?.length > 0 && !isLoading && (
          <h5 className="text-end mb-30">
            <strong> {formatNumber(results.nbHits)}</strong> jobs found
          </h5>
        )}

        {results?.hits.map((row, i) => {
          return <JobCard key={row.objectId} job={row} />
        })}
      </div>
    )
  }
  const renderPagination = () => {
    if (
      results?.nbPages === 0 ||
      results?.nbPages === 1 ||
      isLoading ||
      !results ||
      !results?.hits ||
      error
    ) {
      return <></>
    }
    return (
      <div className="paginations">
        <Pagination
          getPaginationGroup={[...Array(results?.nbPages ?? 0).keys()].map(
            (i) => i + 1,
          )}
          currentPage={results?.page + 1}
          pages={results?.nbPages}
          onPressPage={onPressPage}
        />
      </div>
    )
  }

  return (
    <Layout>
      <SearchBanner onSubmit={onSubmit} queryFilters={query} />
      <section className="section-box mt-30">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
              <div className="content-page">{renderJobs()}</div>
              {renderPagination()}
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="sidebar-shadow none-shadow mb-30">
                <FiltersBar
                  disabled={isLoading}
                  jobPostedCounters={[]}
                  industryCounters={results?.facets?.industry ?? []}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withFirebase(JobsList)
